@media screen and (max-width: 1250px) {
    .card-5-detailed {
        width: 30vw;
    }
    .get-app-btns{
        flex-direction: column;
    }
    /* .get-app-btns button{
        width: 100%;
    } */
}

@media screen and (max-width: 1100px) {
    .card-5-detailed {
        width: 40vw;
    }

    .footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .social-media {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 950px) {

    .home-1,
    .layout,
    .about {
        width: 60vw;
    }
}

@media screen and (max-width: 810px) {

    .home-1,
    .layout,
    .about {
        width: 70vw;
    }
}

@media screen and (max-width: 750px) {
    .card-5-detailed {
        width: 45vw;
    }
}

@media screen and (max-width: 650px) {

    .card-5-detailed {
        width: 50vw;
    }

    .home-1,
    .layout,
    .about {
        width: 80vw;
    }
}

@media screen and (max-width: 600px) {

    .card-5-detailed {
        width: 55vw;
    }

    .join-idea-hacka {
        width: 90%;
    }
}

@media screen and (max-width: 550px) {

    .card-5-detailed {
        width: 70vw;
    }

    .home-1,
    .layout,
    .about {
        border: none;
        width: 90vw;
    }

    .card-3 {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .mobile-navbar {
        display: flex;
        justify-content: space-between;
        padding-inline: 1rem;
        width: 93%;
        align-items: center;
        left: 0;
    }

    .navbar {
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        /* display: none; */
    }

    .navbar-ele {
        margin: 10px auto;
    }

    .desktop-none {
        display: block;
    }

    .category-card {
        font-size: 14px;
    }

    .category-card img {
        height: 40px;
    }

    .join-idea-hacka {
        width: 100%;
    }

    .get-app{
        width: 100%;
    }
}