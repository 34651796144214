@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto+Condensed&display=swap');

.get-app {
    padding-top: 3rem;
    width: 50%;
    margin: auto;
}

.get-app h1 {
    font-size: 3rem;
}

.get-app-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem auto;
    font-family: 'Poppins', sans-serif !important;
}

.get-app-logo {
    height: 30px;
    margin-right: 5px;
}

.get-app-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}

.get-app-img img {
    height: 60px;
}