.footer-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #9e9e9e;
    /* margin-top: 1rem; */
    padding-top: 0.5rem;
    margin: 2rem 0;
}

.footer-ele {
    margin: 5px 15px;
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.get-app-btn {
    margin: auto;
    position: fixed !important;
    bottom: 10px;

}