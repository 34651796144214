.about {
    width: 40vw;
    margin: auto;
    line-height: 1.5;
    padding-top: 20px;
}

.about-nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.about-nav>div {
    cursor: pointer;
    font-size: 20px;
}

.bold-700 {
    font-weight: 700;
    border-bottom: 2px solid #b200ed;
    /* color: ; */
}

.card-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 1rem;
    width: fit-content;
    margin: auto;
    border-radius: 10px;
    margin: 20px 0;
    cursor: pointer;
}

.card-4 img {
    height: 100px;
    aspect-ratio: 1/1;
    margin-right: 40px;
    border-radius: 10px;
}

.member-name {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

p{
    /* text-indent: 7ch; */
    margin: 10px 0;
}