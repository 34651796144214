.ideathon,
.hackathon {
    padding-top: 3rem;
}

.join-idea-hacka {
    width: 70%;
    margin: auto;
    padding-top: 3rem;
}



/* increase margin of buttons */
/* add logo */
/* card-3 width */