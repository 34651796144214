@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

a {
    text-decoration: none;
    color: inherit;
}

.navbar {
    display: flex;
    width: inherit;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
    z-index: 10;
    background-color: rgba(255, 220, 93, 0.881); 
    /* background-color: rgba(255, 255, 255, 0.881); */
    /* background-color: grey; */
    /* padding: 10px 0; */
    padding-top: 10px;
    position: fixed;
    /* width: 100%; */
    top: 0;
    /* left: 0; */
}

.navbar-ele {
    text-transform: uppercase;
    font-family: Roboto condensed;
    /* font-size: 30px; */
    /* padding: 0 1rem; */
}

.btn-1 {
    border-radius: 25px;
    background: #0045F7;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    padding: 0.5rem 1rem;
}

.navbar .active {
    font-weight: 700;
}

.layout {
    width: 55vw;
    margin: auto;
    padding-top: 10vh;
    min-height: 75vh;
}
