.card-5 {
    border-radius: 10px;
    background-color: white;
    padding: 0.5rem 1rem;
    margin: 10px auto;
    cursor: pointer;
}

.card-5-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-5-short {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-5-detailed {
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 23vw;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25), 1px 1px 3px -1px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(4px);
    flex-direction: column-reverse;
}

.card-5-sect {
    display: flex;
    align-items: center;
}

.card-5-sect-3 {
    display: inline-block;
}

.left-auto {
    margin-left: auto;
}

.card-5-description {
    margin: 10px;
    min-width: 90%;
}

.card-5-sect {
    /* margin: 10px 0; */
    margin: 2px 0 5px 0;
}
.card-5-sect-1-icon{
    margin: 0 5px;
}

.card-5-sect-1-img {
    height: 24px;
    aspect-ratio: 1/1;
    background-color: lightgrey;
    border-radius: 50%;
    margin-right: 10px;
}

.card-5-short .media-file {
    width: 128px;
    height: 85px;
    /* border: 1px solid lightgrey; */
}

.card-5-detailed .media-file {
    width: 100%;
    max-height: 85vh;
    margin: auto;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.card-5-detailed .unsupported-media {
    min-height: 30vh;
}

.highlight-1 {
    font-size: 13px;
    color: rgb(67, 136, 255);
}

/* move back button to top */
/* audio file black color */
/*
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-N2H4GRETZ3"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-N2H4GRETZ3');
</script>
*/