.home-1 {
    width: 50vw;
    margin: auto;
}

.trend-comm-screen {
    /* max-height: 70vh; */
    overflow: auto;
}

.home-1-head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 700;
    margin: 2rem 0;
    margin-bottom: 0.5rem;
}

.home-1-head img {
    height: 50px;
}

.input-box-1 {
    width: inherit;
    /* background-color: white; */
    background-color: #fff5d3; 
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 0.25rem;
    display: flex;
    border-radius: 20px;
    margin: 20px auto;
    /* border: 2px solid blue; */
}

.home-1-search {
    width: inherit;
    border: none;
    background-color: transparent;
    /* color: #fff5d3; */
}
.home-1-search::placeholder {
    /* color: #fff5d3; */
}

.home-1-search:focus {
    outline: none;
}

.home-1-box-1 {
    display: flex;
    width: inherit;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem auto;
    font-size: 20px;
}

.home-1-box-1 .selected {
    font-weight: 700;
}

.home-1-box-1-ele {
    cursor: pointer;
}

.home-1-screen {
    display: grid;
    /* grid-template-columns: 1fr 10fr; */
    margin: auto;
    /* overflow: auto; */
}

.margin-auto {
    margin: auto;
}

.right-auto {
    margin-right: auto;
}