.privacy-policy {
    max-height: 70vh;
    overflow: auto;
    padding: 3rem;
    /* border: 1px solid red; */
}

.privacy-policy li {
    margin: 10px 0;
}

.pp-head {
    margin: 1rem 0;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}

.pp-sub-ele {
    margin: 0.5rem;
}

.pp-head-1 {
    font-size: 18px;
    font-weight: 700;
}

ul {
    margin: 5px 10px;
}