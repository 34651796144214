* {
  margin: 0;
  padding: 0;
  font-family: Roboto condensed;
  /* border: 1px solid red; */
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  /* background: linear-gradient(180deg, #FFF 0%, #D8D8D8 100%); */
  background-color: #FFDC5D;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.none,
.desktop-none {
  display: none;
}

.mobile-navbar {
  position: fixed;
  /* width: 100%; */
  z-index: 1;
  /* background-color: white; */
  top: 0;
  display: none;
}

.bold{
  font-weight: bold;
}

input,textarea{
  font-size: 20px;
}