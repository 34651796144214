.community-category {
    display: flex;
    /* display horizontally */
    /* display: inline-flex; */
    width: 100%;
    overflow: auto;
    gap: 8px;
}

.community-category::-webkit-scrollbar {
    height: 5px;
}

/* .community{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
} */

.category-card {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 1px;
    cursor: pointer;
    gap: 2px;
    padding: 5px;
}

.category-card-selected{
    border: 2px solid black;
}

.card-3 {
    margin: 10px auto;
    width: 20vw;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25), 1px 1px 3px -1px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(4px);
    padding: 0.5rem 1rem;
    gap: 1rem;
    width: 90%;

}

.category-card img {
    height: 30px;
    width: 30px;
    margin: 10px;
}

.sm-1 {
    font-size: 12px;
}