.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem 0;
    padding: 3rem 0;
    /* border-radius: 20px; */
}

.contact-title {
    font-size: 1.75rem;
    font-weight: 700;
}

.contact-input {
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 2px 3px 6px -2px rgba(0, 0, 0, 0.28);
    border: none;
    resize: none;
    width: 50%;
}

.contact-input:focus {
    outline: none;
}

.google-form {
    border: none;
    width: 100%;
    height: 75vh;
}

.contact-btn{
    cursor: pointer;
    padding: 0.5rem 2rem;
}